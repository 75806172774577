.heroContainer {
  display: flex;
  flex-direction: column;
  row-gap: 14px;
  align-items: center;
}
.heroContainer_title {
  padding-bottom: 34px;
  font-weight: 600;
}
.heroContainer_image {
  border-radius: 50%;
  width: 52vw;
  height: 52vw;
}
.heroContainer_name {
  width: 310px;
  text-align: center;
}
.heroContainer_profession {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  padding-top: 4px;
}
.menu {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: auto;
  font-size: 16px;
  font-weight: 500;
  line-height: 110%;
  padding-top: 40px;
}
.menu_option {
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  padding: 16px;
  box-sizing: border-box;
}
.menu_option:hover {
  background-color: black;
}
