.App {
  display: flex;
  flex-direction: column;
  min-height: 100dvh;
  width: 100dvw;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}
.title {
  font-family: "Fors Display";
  font-weight: 500;
  font-size: 24px;
  line-height: 140%;
}
.noIdText {
  text-align: center;
  width: 80dvw;
  font-size: 32px;
  margin: auto;
}