.header {
  width: 100%;
  height: 72px;
  min-height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.header_logo {
  position: absolute;
  top: 20px;
  left: 20px;
}
.header_info {
  padding: 5px 12px;
  font-family: "Fors Display";
  font-size: 16px;
  line-height: 140%;
  font-weight: 500;
  border-radius: 50px;
  background-color: #fff;
  color: black;
  height: 32px;
  display: flex;
  align-items: center;
}
.header_back {
  position: absolute;
  top: 20px;
  right: 20px;
}
