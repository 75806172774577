@import url(./Vendor/Fonts/Fors_Display/stylesheet.css);

body {
  margin: 0;
  font-family: 'Fors', 'Helvetica Neue',
    sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1a1919;
  color: #ffffff;
}
