@font-face {
    font-family: 'Fors Display';
    src: local('TypeType - TT Fors Display Variable');
    src: url("TypeType - TT Fors Display Variable.woff2") format("woff2");
    font-style: normal;
}
@font-face {
    font-family: 'Fors';
    src: local('TypeType - TT Fors Variable');
    src: url("TypeType - TT Fors Variable.woff2") format("woff2");
    font-style: normal;
}