.mentions {
  display: flex;
  flex-direction: column;
  gap: 60px;
  align-items: center;
  padding: 0 20px 60px;
}
.mention {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  text-align: center;
}
.mention_image {
  width: 60dvw;
}
.mention_title {
  line-height: 100%;
}
.mention_text {
  font-weight: 400;
}