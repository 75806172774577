.contacts {
  height: 100dvh;
  padding-bottom: 20px;
}
.contacts_image {
  width: 100%;
  margin-bottom: 27px;
  margin-top: 9px;
}
.contacts_texts {
  padding: 0 20px;
}
.contacts_title {
  font-size: 32px;
  line-height: 100%;
  margin-bottom: 16px;
  font-weight: 600;
}
.contacts_subtitle {
  font-size: 20px;
}
.contacts_text {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.contacts_text > p > b {
  font-weight: 600;
}
