.favs {
  display: flex;
  flex-direction: column;
  gap: 49px;
  padding-bottom: 40px;
}
.fav {
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: center;
}
.fav_image {
  background: url("../../Media/backImage.svg") center / cover no-repeat;
  width: 100%;
  min-height: 275px;
}
.fav_text {
  padding: 0 26px;
}
.article {
  background-color: #dec6ab;
  color: black;
  height: 100%;
  overflow: hidden;
  padding-bottom: 40px;
}
.article_backBtn {
  background: url("../../Media/closeBtn.svg") center / 20px 20px no-repeat,
    #1a1d22;
  position: absolute;
  top: 10px;
  right: 10px;
  width: 50px;
  height: 50px;
}
.article_image {
  width: 100%;
}
.article_texts {
  padding: 0 20px;
}
.article_title {
  padding: 27px 0 16px;
  font-weight: 600;
}
.article_subtitle {
  font-size: 20px;
  line-height: 140%;
  font-weight: 500;
  padding-bottom: 20px;
}
